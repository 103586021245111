<template>
    <div id="privacyPolicy">
        <div class="backBtn"><router-link to="/"><img :src="require('@/assets/icons/previous.png')" alt=""><span>BACK</span> </router-link></div>
        <div class="img"><img :src="require('@/assets/logo.png')" alt=""></div>
        <h1>PRIVACY POLICY</h1>
        <h3>AVVISO IMPORTANTE:</h3>
        <p>“Per la registrazione e la conseguente possibilità di utilizzare i servizi di newsletter offerto dal sito womfest.it è necessario che l’utente accetti le seguenti Condizioni generali per l’uso del sito e che fornisca il proprio consenso relativamente al trattamento dei suoi dati personali dopo avere preso visione della informativa ai sensi del D.lgs 196⁄2003 e successive modifiche.”</p>
        
        <h2>CONDIZIONI GENERALI PER L’USO DEL SITO</h2>
        1. Proprietà, Contenuto del Sito, Copyright e Marchi Registrati <br>
        Il sito e tutti i suoi contenuti, quali marchi, logo, icone, testi, ecc., sono di esclusiva proprietà di womfest.it (qui di seguito “Sito”) o dei terzi che forniscono servizi alla Società. Essi sono protetti dalle leggi italiane e internazionali sul diritto di autore. Il software utilizzato nel sito è di proprietà del Sito ed è protetto dalle leggi internazionali sul diritto di autore. Il Sito e tutti suoi contenuti non potranno essere, neanche parzialmente, riprodotti, distribuiti, venduti, trasferiti o modificati senza autorizzazione formale scritta. womfest.it e i nomi di prodotti, servizi, grafici e logo womfest.it sono marchi registrati del Sito. Altri prodotti, servizi, nomi di società citati potrebbero essere marchi registrati dei rispettivi proprietari. Il Sito potrebbe contenere link a siti non gestiti dal Sito stesso, i quali sono forniti come semplice riferimento e per il cui contenuto il Sito non può essere ritenuta responsabile. Il Sito non fornisce alcuna garanzia e declina qualsiasi responsabilità in relazione ai medesimi. Nel caso in cui l’utente decida di consultarli lo fa a proprio rischio.
        2. Uso del sito womfest.it <br>
        Il Sito non potrà essere utilizzato in alcun modo per l’invio di materiale pornografico, o che in ogni caso offenda il senso del comune pudore, o, più in generale, per finalità illecite.<br>
        E’ vietata la riproduzione, la modifica, la duplicazione, la copia, la distribuzione, la vendita o comunque lo sfruttamento del sito per scopi commerciali. E’ ammessa la possibilità di scaricare o stampare una copia dei materiali contenuti nel sito per uso personale e scopi non speculativi. L’uso non autorizzato del sito e del materiale contenuto nel medesimo costituisce violazione delle leggi applicabili sulla proprietà intellettuale. L’utente si impegna a conservare tutte le indicazioni sui marchi e il diritto di autore contenute sui materiali scaricati o copiati. L’uso di questi materiali su qualsiasi altro sito web è vietato.<br>
        3. Età e responsabilità dell’utente<br>
        L’utente deve essere maggiorenne e dotato della capacità d’agire, necessaria per utilizzare il presente sito accettando le presenti Condizioni Generali e per assumersi ogni eventuale responsabilità derivante dall’uso del sito. L’utente che si registra (ove previsto) è obbligato ad indicare esclusivamente i propri dati personali e garantisce che essi siano veritieri, corretti e aggiornati. L’utente prende atto di essere responsabile per qualsiasi uso del sito da parte sua o di chi utilizzi i suoi dati personali.<br>
        4. Esclusione di Garanzia<br>
        Il Sito è fornito da womfest.it “così come è”. Il Sito e i suoi fornitori non rilasciano alcuna garanzia circa l’operatività del sito, l’accuratezza, la completezza, la affidabilità delle informazioni, dei materiali e dei prodotti ivi contenuti. Per quanto consentito dalle leggi applicabili, il Sito non rilascia alcuna garanzia implicita o esplicita di commerciabilità e di idoneità ad uno scopo particolare.<br>
        5. Limitazione di Responsabilità<br>
        La Società non sarà responsabile per i danni di qualsiasi genere conseguenti all’utilizzo del sito, compresi i danni diretti, indiretti, incidentali e consequenziali.<br>
        6. Legge Regolatrice<br>
        Alle presenti Condizioni Generali è applicabile la legge italiana.<br>
        7. Modifiche alle Condizioni Generali<br>
        il Sito si riserva il diritto di modificare il sito e le presenti Condizioni Generali in qualsiasi momento. Delle modifiche verrà data comunicazione nel Sito e, fermo restando il diritto di recesso dell’utente, questi continuerà ad usare il sito secondo la versione modificata delle Condizioni Generali.<br>
        8. Condizioni Ulteriori<br>
        Ulteriori termini e condizioni diversi dalle presenti potranno applicarsi alla vendita di beni e servizi o all’uso di altre parti del sito: l’utente dichiara sin da ora di conformarsi a tali altri termini e condizioni e di essere maggiorenne.
        Informativa ex art. 13 D.lgs. 196/2003<br>
        Il D.lgs. n. 196 del 30 giugno 2003 (“Codice in materia di protezione dei dati personali”) prevede la tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali.<br>
        Secondo la normativa indicata, tale trattamento sarà improntato ai principi di correttezza, liceità e trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.<br>
        Ai sensi dell’articolo 13 del D.lgs. n.196/2003, pertanto, Le forniamo le seguenti informazioni:<br>
        1. I dati da Lei forniti verranno trattati per le seguenti finalità: esclusivamente per adempimenti connessi all’attività economica dell’azienda.<br>
        2. Il trattamento sarà effettuato con le seguenti modalità: manuali e informatizzate con logiche legate strettamente alle finalità stesse.<br>
        3. Il conferimento dei dati è facoltativo ma l’eventuale rifiuto di fornire tali dati potrebbe comportare la mancata o parziale esecuzione del contratto o la mancata prosecuzione del rapporto.<br>
        4. I dati potranno essere comunicati al fine di consentire l’adempimento degli obblighi contrattuali o di legge a:<br>
        - tutti quei soggetti cui la facoltà di accesso a tali dati è riconosciuta in forza di provvedimenti normativi;<br>
        - nostri collaboratori o dipendenti nell’ambito delle relative mansioni;<br>
        - Istituti bancari per la gestione di incassi;<br>
        5. Il titolare del trattamento è womfest.it<br>
        6. Il responsabile del trattamento è il succitato womfest.it.<br>
        7. In ogni momento potrà esercitare i Suoi diritti nei confronti del titolare del trattamento, ai sensi dell’art.7 del D.lgs.196/2003, che per Sua comodità riproduciamo integralmente:<br>
        Decreto Legislativo n.196/2003,Art. 7 – Diritto di accesso ai dati personali ed altri diritti<br>
        1. L’interessato ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.<br>
        2. L’interessato ha diritto di ottenere l’indicazione:<br>
        a) dell’origine dei dati personali;b) delle finalità e modalità del trattamento;c) della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici;d) degli estremi identificativi del titolare, dei responsabili e del rappresentante designato ai sensi dell’articolo 5, comma 2;e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati.<br>
        3. L’interessato ha diritto di ottenere:<br>
        a) l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei dati;
        b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;
        c) l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.<br>
        4. L’interessato ha diritto di opporsi, in tutto o in parte:<br>
        a) per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta;b) al trattamento di dati personali che lo riguardano a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale.
        Per esercitare i propri diritti previsti all’articolo 7 l’interessato dovrà rivolgersi al succitato womfest.it.

        <Footer />
    </div>
</template>

<script>
import Footer from '../components/footer.vue';
export default {
    components:{Footer}
}
</script>

<style lang="scss" scoped>
#privacyPolicy{
    color: rgb(26, 26, 26);
    max-width: 1400px;
    margin: 0 auto;
    font-size: 10pt;
    position: relative;
    padding: 0px 20px;
    padding-top: 100px;
    .backBtn{
        position: absolute;
        top:20px;
        left:20px;
        img{
            width: 20px;
            transition-duration: 0.2s;
        }
        span{
            position: absolute;
            top:45%;
            transform: translateY(-50%);
            left: 25px;
            font-weight: 800;
            transition-duration: 0.2s;
        }
        a{
            &:hover{
                img{transform: scale(1.1);transition-duration: 0.2s;}
                span{font-size: 12pt;transition-duration: 0.2s;}
            }
        }
    }
    .img{
        text-align: center;
        img{
            max-width: 100%;
            filter: invert(1);
        }
    }
    h1{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 25pt;
        font-weight: 800;
    }
    h3{
        font-weight: 800;
        font-size: 17pt;
        margin-bottom: 10px;
    }
    h2{
        font-size: 20pt;
        line-height: 22px;
        font-weight: 800;
        margin-top: 20px;
        margin-bottom: 5px;
    }
}
</style>